import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default function WysiwygField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur }) {
    return (
        <div className={className}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>
            <div className='mt-2' onBlur={onBlur}>
                <Editor
                    initialContentState={!value ? { blocks: [], entityMap: {} } : !value.entityMap ? { entityMap: {}, ...value } : value}
                    // contentState={!value ? { blocks: [], entityMap: {} } : !value.entityMap ? { entityMap: {}, ...value } : value}
                    editorClassName={`bg-white border px-3 rounded ${inputClass}`}
                    onContentStateChange={(e) => {
                        onChange(e)
                    }}
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
}
