import Select from 'react-select'

import Loading from '../../components/loading'

export default function MultiSelectField({ label, value, onChange, className, labelClass, options }) {
    if (!options) return <Loading></Loading>

    return (
        <div className={className}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>
            <div className='relative mt-2'>
                <Select value={options.filter((x) => value?.includes(x.value))} onChange={(e) => onChange(e.map((x) => x.value))} isMulti={true} getOptionLabel={(x) => x.text} getOptionValue={(x) => x.value} options={options} classNamePrefix='select' />
            </div>
        </div>
    )
}
