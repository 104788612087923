import { MetricCard } from './tracking-common'

const BLOOD_GENERAL_METRICS = [
    { label: 'Creatinine', unit: 'µmol/L', type: 'creatinine' },
    { label: 'eGFR', unit: 'mL/min', type: 'egfr' },
    { label: 'HBA1c', unit: 'mmol/mol', type: 'hba1c' },
    { label: 'Fasting Blood Sugar', unit: 'mmol/L', type: 'hba1c' },
    { label: 'Total Cholesterol', unit: 'mmol/L', type: 'total_cholesterol' },
    { label: 'HDL', unit: 'mmol/L', type: 'hdl' },
    { label: 'LDL', unit: 'mmol/L', type: 'ldl' },
    { label: 'Triglycerides', unit: 'mmol/L', type: 'triglycerides' },
    { label: 'Cholesterol ratio', unit: '', type: 'cholesterol_ratio' },
    { label: 'Gamma GT', unit: 'IU/L', type: 'gamma_gt' },
    { label: 'AST', unit: 'IU/L', type: 'ast' },
    { label: 'ALT', unit: 'IU/L', type: 'alt' },
    { label: 'ALP', unit: 'IU/L', type: 'alp' },
]

const BLOOD_SPECIAL_METRICS = [
    { label: 'Viral Load', unit: 'copies/ml', type: 'viral_load' },
    { label: 'CD4', unit: 'cells/mm³', type: 'cd4' },
]

export function TrackingBloodGeneral({ data, getValue, setModalOpen }) {
    return (
        <div className='animate-fade'>
            <div className='my-3 text-xl font-semibold tracking-tight text-slate-700'>General Blood Results</div>
            {BLOOD_GENERAL_METRICS.map((x) => (
                <MetricCard metric={x} data={data} getValue={getValue} setModalOpen={setModalOpen} />
            ))}
        </div>
    )
}

export function TrackingBloodSpecial({ data, getValue, setModalOpen }) {
    return (
        <div className='animate-fade'>
            <div className='my-3 text-xl font-semibold tracking-tight text-slate-700'>Specialist Blood Results</div>
            {BLOOD_SPECIAL_METRICS.map((x) => (
                <MetricCard metric={x} data={data} getValue={getValue} setModalOpen={setModalOpen} />
            ))}
        </div>
    )
}
