import { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import { api } from '../services/api.service'

import Button from '../components/button'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import { WysiwygField } from '../forms/fields'

import 'react-toastify/dist/ReactToastify.css'

export default function AdminHomepage() {
    const [homepage, setHomepage] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/homepage`).then((x) => setHomepage(x))
    }, [])

    function submit() {
        api(`${process.env.REACT_APP_API_URL}/app/homepage`, { data: homepage }).then((x) => {
            toast.success('Homepage content updated')
        })
    }

    if (!homepage) return <Loading />

    return (
        <>
            <ToastContainer position='top-right' theme='light' autoClose={3000} />
            <PageHeader headline={'Homepage Content'} actions={<Button text='Save Content' onClick={() => submit()}></Button>}></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-4 2xl:max-w-6xl'>
                    <section className='mx-4 mb-8'>
                        <h1 className='m-4 text-xl font-bold'>What is Immunity Health?</h1>
                        <WysiwygField value={homepage.about} onChange={(val) => setHomepage({ ...homepage, about: val })} />
                    </section>
                    <section className='mx-4 mb-8'>
                        <h1 className='m-4 text-xl font-bold'>Why Lifestyle Medicine?</h1>
                        <WysiwygField value={homepage.why} onChange={(val) => setHomepage({ ...homepage, why: val })} />
                    </section>
                    <section className='mx-4 mb-8'>
                        <h1 className='m-4 text-xl font-bold'>Meet the Team</h1>
                        <WysiwygField value={homepage.team} onChange={(val) => setHomepage({ ...homepage, team: val })} />
                    </section>
                </div>
            </div>
        </>
    )
}
