import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../services/api.service'

import Button from '../components/button'

import { CheckboxField, SelectField, TextField } from '../forms/fields'

export default function HealthAssessments() {
    const [values, setValues] = useState({})

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setValues(x.healthAssessment ?? {}))
    }, [])

    const submit = () => {
        api(`${process.env.REACT_APP_API_URL}/app/assessment`, { data: values }).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setValues(x.healthAssessment ?? {}))
        })
    }

    return (
        <div className='animate-fade opacity-80 pointer-events-none cursor-default'>
            <div className='my-5 text-3xl font-black tracking-tight text-center text-slate-700'>Health Assessment</div>

            <QRisk values={values} setValues={setValues} submit={submit} />
        </div>
    )
}

function QRisk({ values, setValues, submit }) {
    const [tab, setTab] = useState(0)
    const maxTab = 4
    const navigate = useNavigate()

    return (
        <>
            <div className='grid grid-cols-2 sm:grid-cols-5 mb-8 overflow-hidden font-semibold bg-white rounded-lg shadow-sm gap-x-4'>
                <div onClick={() => setTab(0)} className={`${tab === 0 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    About You
                </div>
                <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    Medical History
                </div>
                <div onClick={() => setTab(2)} className={`${tab === 2 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    Lifestyle
                </div>
                <div onClick={() => setTab(3)} className={`${tab === 3 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    Quality of Life
                </div>
                <div onClick={() => setTab(4)} className={`${tab === 4 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-4 text-center cursor-pointer`}>
                    Assessments
                </div>
            </div>

            {tab === 0 && (
                <>
                    <div className='p-5 bg-white rounded-b-lg shadow'>
                        <TextField label='Age' value={values.age} onChange={(val) => setValues({ ...values, age: val })} />
                        <SelectField
                            options={[
                                { text: 'Female', value: 'Female' },
                                { text: 'Male', value: 'Male' },
                                { text: 'Other', value: 'Other' },
                                { text: 'Prefer not to say', value: 'Prefer not to say' },
                            ]}
                            label='Gender'
                            value={values.gender}
                            onChange={(val) => setValues({ ...values, gender: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'Female', value: 'Female' },
                                { text: 'Male', value: 'Male' },
                                { text: 'Prefer not to say', value: 'Prefer not to say' },
                            ]}
                            label='Gender assigned at birth'
                            value={values.sex}
                            onChange={(val) => setValues({ ...values, sex: val })}
                        />

                        {/* <TextField label='UK Postcode' value={values.postcode} onChange={(val) => setValues({ ...values, postcode: val })} /> */}

                        <SelectField
                            options={[
                                { text: 'White', value: 'White' },
                                // { text: 'English, Welsh, Scottish, Northern Irish or British', value: 'English, Welsh, Scottish, Northern Irish or British' },
                                // { text: 'Irish', value: 'Irish' },
                                // { text: 'Gypsy or Irish Traveller', value: 'Gypsy or Irish Traveller' },
                                // { text: 'Roma', value: 'Roma' },
                                // { text: 'Any other White background', value: 'Any other White background' },

                                { text: 'Indian', value: 'Indian' },
                                { text: 'Pakistani', value: 'Pakistani' },
                                { text: 'Bangladeshi', value: 'Bangladeshi' },
                                { text: 'Chinese', value: 'Chinese' },
                                { text: 'Any other Asian background', value: 'Any other Asian background' },

                                { text: 'Caribbean', value: 'Caribbean' },
                                { text: 'African', value: 'African' },
                                // { text: 'Any other Black, Black British, or Caribbean background', value: 'Any other Black, Black British, or Caribbean background' },

                                // { text: 'White and Black Caribbean', value: 'White and Black Caribbean' },
                                // { text: 'White and Black African', value: 'White and Black African' },
                                // { text: 'White and Asian', value: 'White and Asian' },
                                // { text: 'Any other Mixed or multiple ethnic background', value: 'Any other Mixed or multiple ethnic background' },

                                // { text: 'Arab', value: 'Arab' },
                                { text: 'Any other ethnic group', value: 'Any other ethnic group' },
                            ]}
                            label='Ethnicity'
                            value={values.ethnicity}
                            onChange={(val) => setValues({ ...values, ethnicity: val })}
                        />
                        {/* <SelectField
                            options={[
                                { text: 'Heterosexual', value: 'Heterosexual' },
                                { text: 'Gay', value: 'Gay' },
                                { text: 'Lesbian', value: 'Lesbian' },
                                { text: 'Bisexual', value: 'Bisexual' },
                                { text: 'Other', value: 'Other' },
                                { text: 'Prefer not to say', value: 'Prefer not to say' },
                            ]}
                            label='Sexual Orientation'
                            value={values.sexualOrientation}
                            onChange={(val) => setValues({ ...values, sexualOrientation: val })}
                        /> */}
                    </div>
                </>
            )}
            {tab === 1 && (
                <>
                    <div className='p-5 bg-white rounded-b-lg shadow'>
                        {CONDITIONS.map(({ conditionKey, name }, idx) => (
                            <div key={idx} className='flex flex-col sm:flex-row justify-between py-2 item-center mt-5 sm:mt-0'>
                                <div className='flex items-center'>{name}</div>
                                <div className='flex space-x-4 items-end'>
                                    {CONDITION_STATES.map((conditionStateLabel, conditionStateCode) => (
                                        <CheckboxField
                                            labelClass='text-center'
                                            inputClass='mx-auto'
                                            label={conditionStateLabel}
                                            value={values[conditionKey] === conditionStateCode}
                                            onChange={(val) => {
                                                if (val !== values[conditionKey]) {
                                                    setValues({ ...values, [conditionKey]: conditionStateCode })
                                                }
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {tab === 2 && (
                <>
                    <div className='p-5 bg-white rounded-b-lg shadow'>
                        <SelectField
                            options={[
                                { text: 'Never Smoked', value: 'Never Smoked' },
                                { text: 'Ex-Smoker', value: 'Ex-Smoker' },
                                { text: 'Light Smoker (1-10)', value: 'Light Smoker (1-10)' },
                                { text: 'Moderate Smoker (10-20)', value: 'Moderate Smoker (10-20)' },
                                { text: 'Heavy Smoker (over 20)', value: 'Heavy Smoker (over 20)' },

                            ]}
                            label='Smoking Status'
                            value={values.smokingStatus}
                            onChange={(val) => setValues({ ...values, smokingStatus: val })}
                        />
                        {/* <SelectField
                            options={[
                                { text: 'None', value: 'None' },
                                { text: 'At Risk', value: 'At Risk' },
                                { text: 'Have Diabetes', value: 'Have Diabetes' },
                            ]}
                            label='Diabetes Status'
                            value={values.diabetesStatus}
                            onChange={(val) => setValues({ ...values, diabetesStatus: val })}
                        /> */}
                        <SelectField
                            options={[
                                { text: 'Never', value: 'Never' },
                                { text: 'Monthly or less', value: 'Monthly or less' },
                                { text: '2 - 4 times per month', value: '2 - 4 times per month' },
                                { text: '2 - 3 times per week', value: '2 - 3 times per week' },
                                { text: '4+ times per week', value: '4+ times per week' },
                            ]}
                            label='How often do you have a drink containing alcohol?'
                            value={values.alcoholFrequency}
                            onChange={(val) => setValues({ ...values, alcoholFrequency: val })}
                        />
                        <SelectField
                            options={[
                                { text: '1 - 2', value: '1 - 2' },
                                { text: '3 - 4', value: '3 - 4' },
                                { text: '5 - 6', value: '5 - 6' },
                                { text: '7 - 9', value: '7 - 9' },
                                { text: '10+', value: '10+' },
                            ]}
                            label='How many units of alcohol do you drink on a typical day when you are drinking?'
                            value={values.alcoholUnits}
                            onChange={(val) => setValues({ ...values, alcoholUnits: val })}
                        />
                        <img className="mx-auto my-3 shadow" src='units.png' />
                        <SelectField
                            options={[
                                { text: 'Never', value: 'Never' },
                                { text: 'Less than monthly', value: 'Less than monthly' },
                                { text: 'Monthly', value: 'Monthly' },
                                { text: 'Weekly', value: 'Weekly' },
                                { text: 'Daily or almost daily', value: 'Daily or almost daily' },
                            ]}
                            label='How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?'
                            value={values.alcoholSixPlus}
                            onChange={(val) => setValues({ ...values, alcoholSixPlus: val })}
                        />

                        <SelectField
                            options={[
                                { text: 'Yes', value: 'Yes' },
                                { text: 'No', value: 'No' }]}
                            label='Do you walk more than 5000 steps a day or do regular aerobic exercise?'
                            value={values.workActivity}
                            onChange={(val) => setValues({ ...values, workActivity: val })}
                        />

                        <SelectField
                            options={[
                                { text: 'I am not in employment (e.g. retired, retired for health reasons, unemployed, full-time carer etc.)', value: 'I am not in employment (e.g. retired, retired for health reasons, unemployed, full-time carer etc.)' },
                                { text: 'I spend most of my time at work sitting (such as in an office)', value: 'I spend most of my time at work sitting (such as in an office)' },
                                { text: 'I spend most of my time at work standing or walking. However, my work does not require much intense physical effort (e.g. shop assistant, hairdresser, security guard, childminder, etc.)', value: 'I spend most of my time at work standing or walking. However, my work does not require much intense physical effort (e.g. shop assistant, hairdresser, security guard, childminder, etc.)' },
                                { text: 'My work involves definite physical effort including handling of heavy objects and use of tools (e.g. plumber, electrician, carpenter, cleaner, hospital nurse, gardener, postal delivery workers etc.)', value: 'My work involves definite physical effort including handling of heavy objects and use of tools (e.g. plumber, electrician, carpenter, cleaner, hospital nurse, gardener, postal delivery workers etc.)' },
                                { text: 'My work involves vigorous physical activity including handling of very heavy objects (e.g. scaffolder, construction worker, refuse collector, etc.)', value: 'My work involves vigorous physical activity including handling of very heavy objects (e.g. scaffolder, construction worker, refuse collector, etc.)' },
                            ]}
                            label='Please tell us the type and amount of physical activity involved in your work.'
                            value={values.workActivity}
                            onChange={(val) => setValues({ ...values, workActivity: val })}
                        />

                        <h2 className='mt-6'>During the last week, how many hours did you spend on each of the following activities? Please answer whether you are in employment or not (pick one for each activity)</h2>
                        <SelectField
                            options={[
                                { text: 'None', value: 'None' },
                                { text: 'Some but less than 1 hour', value: 'Some but less than 1 hour' },
                                { text: '1 hour but less than 3 hours', value: '1 hour but less than 3 hours' },
                                { text: '3 hours or more', value: '3 hours or more' },
                            ]}
                            label='Physical exercise such as swimming, jogging, aerobics, football, tennis, gym workout etc.'
                            value={values.physicalExerciseTime}
                            onChange={(val) => setValues({ ...values, physicalExerciseTime: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'None', value: 'None' },
                                { text: 'Some but less than 1 hour', value: 'Some but less than 1 hour' },
                                { text: '1 hour but less than 3 hours', value: '1 hour but less than 3 hours' },
                                { text: '3 hours or more', value: '3 hours or more' },
                            ]}
                            label='Cycling, including cycling to work and during leisure time'
                            value={values.cyclingTime}
                            onChange={(val) => setValues({ ...values, cyclingTime: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'None', value: 'None' },
                                { text: 'Some but less than 1 hour', value: 'Some but less than 1 hour' },
                                { text: '1 hour but less than 3 hours', value: '1 hour but less than 3 hours' },
                                { text: '3 hours or more', value: '3 hours or more' },
                            ]}
                            label='Walking, including walking to work, shopping, for pleasure etc.'
                            value={values.walkingTime}
                            onChange={(val) => setValues({ ...values, walkingTime: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'None', value: 'None' },
                                { text: 'Some but less than 1 hour', value: 'Some but less than 1 hour' },
                                { text: '1 hour but less than 3 hours', value: '1 hour but less than 3 hours' },
                                { text: '3 hours or more', value: '3 hours or more' },
                            ]}
                            label='Housework/Childcare'
                            value={values.houseworkTime}
                            onChange={(val) => setValues({ ...values, houseworkTime: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'None', value: 'None' },
                                { text: 'Some but less than 1 hour', value: 'Some but less than 1 hour' },
                                { text: '1 hour but less than 3 hours', value: '1 hour but less than 3 hours' },
                                { text: '3 hours or more', value: '3 hours or more' },
                            ]}
                            label='Gardening/DIY'
                            value={values.gardeningTime}
                            onChange={(val) => setValues({ ...values, gardeningTime: val })}
                        />
                        <hr className='my-4' />
                        <SelectField
                            options={[
                                { text: 'Slow pace (less than 3 mph)', value: 'Slow pace (less than 3 mph)' },
                                { text: 'Steady average pace', value: 'Steady average pace' },
                                { text: 'Brisk pace', value: 'Brisk pace' },
                                { text: 'Fast pace (more than 4 mph)', value: 'Fast pace (more than 4 mph)' },
                            ]}
                            label='How would you describe your usual walking pace?'
                            value={values.walkingPace}
                            onChange={(val) => setValues({ ...values, walkingPace: val })}
                        />
                        <hr className='my-4' />

                        <TextField label={'During the past 7 days, how would you rate your sleep quality overall? (1-10)'} value={values.sleepQuality} onChange={(val) => setValues({ ...values, sleepQuality: val })} />
                    </div>
                </>
            )}
            {tab === 3 && (
                <>
                    <div className='p-5 bg-white rounded-b-lg shadow'>
                        <div>Please select the ONE selection option that best describes your health TODAY.</div>
                        <SelectField
                            options={[
                                { text: 'I have no problems in walking about', value: 'I have no problems in walking about' },
                                { text: 'I have slight problems in walking about', value: 'I have slight problems in walking about' },
                                { text: 'I have moderate problems in walking about', value: 'I have moderate problems in walking about' },
                                { text: 'I have severe problems in walking about', value: 'I have severe problems in walking about' },
                                { text: 'I am unable to walk about', value: 'I am unable to walk about' },
                            ]}
                            label='Mobility'
                            value={values.qol_mobility}
                            onChange={(val) => setValues({ ...values, qol_mobility: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'I have no problems washing or dressing myself', value: 'I have no problems washing or dressing myself' },
                                { text: 'I have slight problems washing or dressing myself', value: 'I have slight problems washing or dressing myself' },
                                { text: 'I have moderate problems washing or dressing myself', value: 'I have moderate problems washing or dressing myself' },
                                { text: 'I have severe problems washing or dressing myself', value: 'I have severe problems washing or dressing myself' },
                                { text: 'I am unable to wash or dress myself', value: 'I am unable to wash or dress myself' },
                            ]}
                            label='Self-Care'
                            value={values.qol_selfCare}
                            onChange={(val) => setValues({ ...values, qol_selfCare: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'I have no problems doing my usual activities', value: 'I have no problems doing my usual activities' },
                                { text: 'I have slight problems doing my usual activities', value: 'I have slight problems doing my usual activities' },
                                { text: 'I have moderate problems doing my usual activities', value: 'I have moderate problems doing my usual activities' },
                                { text: 'I have severe problems doing my usual activities', value: 'I have severe problems doing my usual activities' },
                                { text: 'I am unable to do my usual activities', value: 'I am unable to do my usual activities' },
                            ]}
                            label='USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)'
                            value={values.qol_usualActivities}
                            onChange={(val) => setValues({ ...values, qol_usualActivities: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'I have no pain or discomfort', value: 'I have no pain or discomfort' },
                                { text: 'I have slight pain or discomfort', value: 'I have slight pain or discomfort' },
                                { text: 'I have moderate pain or discomfort', value: 'I have moderate pain or discomfort' },
                                { text: 'I have severe pain or discomfort', value: 'I have severe pain or discomfort' },
                                { text: 'I have extreme pain or discomfort', value: 'I have extreme pain or discomfort' },
                            ]}
                            label='Pain / Discomfort'
                            value={values.qol_painDiscomfort}
                            onChange={(val) => setValues({ ...values, qol_painDiscomfort: val })}
                        />
                        <SelectField
                            options={[
                                { text: 'I am not anxious or depressed', value: 'I am not anxious or depressed' },
                                { text: 'I am slightly anxious or depressed', value: 'I am slightly anxious or depressed' },
                                { text: 'I am moderately anxious or depressed', value: 'I am moderately anxious or depressed' },
                                { text: 'I am severely anxious or depressed', value: 'I am severely anxious or depressed' },
                                { text: 'I am extremely anxious or depressed', value: 'I am extremely anxious or depressed' },
                            ]}
                            label='Anxiety / Depression'
                            value={values.qol_anxietyDepression}
                            onChange={(val) => setValues({ ...values, qol_anxietyDepression: val })}
                        />

                        <p className='mt-5'>We would like to know how good or bad your health is TODAY.</p>
                        <p>You will see a scale numbered from 0 to 100.</p>
                        <p>
                            100 means the <u>best</u> health you can imagine.
                        </p>
                        <p>
                            0 means the <u>worst</u> health you can imagine.
                        </p>
                        <p>Please indicate on the scale how your health is TODAY.</p>

                        <div className='flex justify-between'>
                            <div>0</div>
                            <div>{values.qol_range}</div>
                            <div>100</div>
                        </div>
                        <input className='w-full' type='range' min='0' max='100' value={values.qol_range} onChange={(val) => setValues({ ...values, qol_range: val.target.value })} />

                        <div>
                            <i>© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v1.0</i>
                        </div>
                    </div>
                </>
            )}
            {tab === 4 && (
                <>
                    <div className='p-5 bg-white rounded-b-lg shadow'>
                        {/* <CheckboxField inline label='Angina or heat attack in a 1st degree relative < 60?' value={values.anginaInRelative} onChange={(val) => setValues({ ...values, anginaInRelative: val })} />
                        <CheckboxField inline label='Chronic kidney disease (stage 3,4 or 5)?' value={values.kidneyDisease} onChange={(val) => setValues({ ...values, kidneyDisease: val })} />
                        <CheckboxField inline label='Atrial fibrillation?' value={values.atrialFibrillation} onChange={(val) => setValues({ ...values, atrialFibrillation: val })} />
                        <CheckboxField inline label='On blood pressure treatment?' value={values.bloodPressureTreatment} onChange={(val) => setValues({ ...values, bloodPressureTreatment: val })} />
                        <CheckboxField inline label='Do you have migraines?' value={values.migraines} onChange={(val) => setValues({ ...values, migraines: val })} />
                        <CheckboxField inline label='Rheumatoid arthritis?' value={values.rheumatoidArthritis} onChange={(val) => setValues({ ...values, rheumatoidArthritis: val })} />
                        <CheckboxField inline label='Systemic lupus erythematosus (SLE)?' value={values.sle} onChange={(val) => setValues({ ...values, sle: val })} />
                        <CheckboxField inline label='Severe mental illness?' value={values.mentalIllness} onChange={(val) => setValues({ ...values, mentalIllness: val })} />
                        <CheckboxField inline label='On atypical antipsychotic medication?' value={values.antipsychoticMedication} onChange={(val) => setValues({ ...values, antipsychoticMedication: val })} />
                        <CheckboxField inline label='Are you on regular steroid tablets?' value={values.steroidTablets} onChange={(val) => setValues({ ...values, steroidTablets: val })} />
                        <CheckboxField inline label='A diagnosis of or treatment for erectile disfunction?' value={values.ed} onChange={(val) => setValues({ ...values, ed: val })} /> */}

                        <div className='p-5 mt-5'>
                            You can calculate your QRisk score on the QRISK3 site
                            <a className='block mt-2 text-xl font-bold text-blue-500' href='https://www.qrisk.org/' target={'_blank'}>
                                Go To QRISK3
                            </a>
                            <TextField label='QRISK3 Score' value={values.qrisk} onChange={(val) => setValues({ ...values, qrisk: val })} />
                        </div>

                        <div className='p-5 '>
                            You can calculate your FRAX score on the FRAX calculator
                            <a className='block mt-2 text-xl font-bold text-blue-500' href='https://frax.shef.ac.uk/FRAX/tool.aspx?country=1' target={'_blank'}>
                                Go To FRAX
                            </a>
                            <TextField label='FRAX Result' value={values.frax} onChange={(val) => setValues({ ...values, frax: val })} />
                        </div>
                    </div>
                </>
            )}

            <div className='flex justify-between my-5'>
                <div>
                    {tab >= 1 && (
                        <Button
                            text='Back'
                            onClick={() => {
                                setTab(tab - 1)
                            }}
                        />
                    )}
                </div>

                {tab != maxTab && (
                    <Button
                        text='Next'
                        onClick={() => {
                            submit()
                            setTab(tab + 1)
                        }}
                    />
                )}
                {tab == maxTab && (
                    <Button
                        text='Finish'
                        onClick={() => {
                            submit()
                            navigate(-1)
                        }}
                    />
                )}
            </div>
        </>
    )
}

const CONDITIONS = [
    { conditionKey: 'diabetes', name: 'Diabetes / Pre-Diabetes' },
    { conditionKey: 'highBloodPressure', name: 'High Blood Pressure' },
    { conditionKey: 'highCholesterol', name: 'High Cholesterol' },
    { conditionKey: 'osteoporosis', name: 'Osteoporosis / Osteopenia' },
    { conditionKey: 'depressionAnxiety', name: 'Depression / Anxiety' },
]

const CONDITION_STATES = ['No', 'Yes', 'On treatment', 'Not on treatment', 'Don\'t know',]
