import draftToHtml from 'draftjs-to-html'
import moment from 'moment'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../services/api.service'

import Button from '../components/button'

export default function Home() {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [tracking, setTracking] = useState()
    const [openTabs, setOpenTabs] = useState([])
    const [content, setContent] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setData(x.user))
        api(`${process.env.REACT_APP_API_URL}/app/tracking`).then((x) => setTracking(x.tracking))
        api(`${process.env.REACT_APP_API_URL}/app/homepage`).then((x) => setContent(x))
    }, [])

    return (
        <>
            <div className='my-5 text-3xl font-black tracking-tight text-center text-slate-700'>Home</div>

            {!data?.healthAssessment?.age && (
                <>
                    <div className='p-5 mt-5 text-center text-white bg-blue-500 shadow animate-fade-right rounded-xl'>
                        <div>Complete a health assessment to get started</div>
                        <Button className='mt-3' text='Go To Assessment' onClick={() => navigate('/health-assessment')} />
                    </div>
                </>
            )}

            {!tracking?.length && (
                <>
                    <div className='p-5 mt-5 text-center text-white bg-blue-500 shadow animate-fade-right rounded-xl'>
                        <div>Upload your first health metrics & blood results as a baseline</div>
                        <Button className='mt-3' text='Go To Tracking' onClick={() => navigate('/tracking')} />
                    </div>
                </>
            )}

            {moment(data?.startDate).startOf('day').add(14, 'days').toDate() <= moment().startOf('day').toDate() && !tracking?.filter((x) => moment(x.date).startOf('day').toDate() >= moment(data?.startDate).startOf('day').add(14, 'days').toDate())?.length && (
                <>
                    <div className='p-5 mt-5 text-center text-white bg-blue-500 shadow animate-fade-right rounded-xl'>
                        <div>Upload your two week updated health metrics & blood results to track your progress</div>
                        <Button className='mt-3' text='Go To Tracking' onClick={() => navigate('/tracking')} />
                    </div>
                </>
            )}

            {moment(data?.startDate).startOf('day').add(28, 'days').toDate() <= moment().startOf('day').toDate() && !tracking?.filter((x) => moment(x.date).startOf('day').toDate() >= moment(data?.startDate).startOf('day').add(28, 'days').toDate())?.length && (
                <>
                    <div className='p-5 mt-5 text-center text-white bg-blue-500 shadow animate-fade-right rounded-xl'>
                        <div>Upload your four week updated health metrics & blood results to track your progress</div>
                        <Button className='mt-3' text='Go To Tracking' onClick={() => navigate('/tracking')} />
                    </div>
                </>
            )}

            <div onClick={() => (openTabs.includes(0) ? setOpenTabs([...openTabs.filter((x) => x != 0)]) : setOpenTabs(openTabs.concat([0])))} className='p-5 my-5 font-medium bg-white shadow cursor-pointer transition-slowest ease transition-height animate-fade-right text-neutral-700 rounded-xl'>
                <div className='text-xl font-semibold text-slate-800'>What is Immunity Health?</div>
                {openTabs.includes(0) && <div className='mt-3 text-slate-700'>{content?.about && <p className='markup' dangerouslySetInnerHTML={{ __html: draftToHtml(content.about) }}></p>}</div>}
            </div>

            <div onClick={() => (openTabs.includes(1) ? setOpenTabs([...openTabs.filter((x) => x != 1)]) : setOpenTabs(openTabs.concat([1])))} className='p-5 my-5 font-medium bg-white shadow cursor-pointer transition-slowest ease transition-height animate-delay-100 animate-fade-right text-neutral-700 rounded-xl'>
                <div className='text-xl font-semibold text-slate-800'>Why Lifestyle Medicine?</div>
                {openTabs.includes(1) && <div className='mt-3 text-slate-700'>{content?.why && <p className='markup' dangerouslySetInnerHTML={{ __html: draftToHtml(content.why) }}></p>}</div>}
            </div>

            <div onClick={() => (openTabs.includes(2) ? setOpenTabs([...openTabs.filter((x) => x != 2)]) : setOpenTabs(openTabs.concat([2])))} className='p-5 my-5 font-medium bg-white shadow cursor-pointer transition-slowest ease transition-height animate-delay-200 animate-fade-right text-neutral-700 rounded-xl'>
                <div className='flex items-center space-x-5'>
                    {/* <img src='/team.jpg' className='object-cover w-24 h-24 rounded shadow'></img> */}

                    <div className='text-xl font-semibold text-slate-800'>Meet the team</div>
                </div>

                {openTabs.includes(2) && <div className='mt-3 text-slate-700'>{content?.team && <p className='markup' dangerouslySetInnerHTML={{ __html: draftToHtml(content.team) }}></p>}</div>}
            </div>

            <div onClick={() => navigate('/logout')} className='p-3 px-5 my-5 font-medium bg-white shadow cursor-pointer transition-slowest ease transition-height animate-delay-300 animate-fade-right text-neutral-700 rounded-xl'>
                <div className='text-xl font-semibold text-slate-800 '>
                    <ArrowLeftOnRectangleIcon className='inline w-6 mr-3' /> Logout
                </div>
            </div>
        </>
    )
}
