import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/error-boundary'

import LessonForm from './forms/lesson-form'
import UserForm from './forms/user-form'

import AdminHomepage from './admin/admin-homepage'
import AdminLessons from './admin/admin-lessons'
import AdminUsers from './admin/admin-users'
import AdminLayout from './admin/layout'
import Dashboard from './app/dashboard'
import HealthAssessments from './app/health-assessments'
import Home from './app/home'
import AppLayout from './app/layout'
import Lesson from './app/lesson'
import Lessons from './app/lessons'
import Tracking from './app/tracking'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import RequestReset from './auth/request-reset'
import ResetPassword from "./auth/reset-password"

export default function App() {
    return (
        <ErrorBoundary componentName='App (top level catch)'>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/request-reset' element={<RequestReset />} />
                <Route path='/logout' element={<Logout />} />
                <Route path="/passwordReset" element={<ResetPassword />} />
                
                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Home />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/programme' element={<Lessons />} />
                    <Route path='/lesson/:id' element={<Lesson />} />
                    <Route path='/health-assessment' element={<HealthAssessments />} />
                    <Route path='/tracking' element={<Tracking />} />
                </Route>

                <Route path='/admin' element={<AdminLayout />}>
                    <Route index element={<AdminUsers />} />
                    <Route path='/admin/manage-user/:id' element={<UserForm />} />
                    <Route path='/admin/lessons' element={<AdminLessons />} />
                    <Route path='/admin/manage-lesson/:id' element={<LessonForm />} />
                    <Route path='/admin/homepage' element={<AdminHomepage />} />
                </Route>
            </Routes>
        </ErrorBoundary>
    )
}
