import { useEffect, useState } from 'react'

import moment from 'moment'
import { api } from '../services/api.service'

export default function Dashboard() {
    const [data, setData] = useState()
    const [userData, setUserData] = useState()
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => setUserData(x.user))
    }, [])

    return (
        <div className="animate-fade">
            <div className="my-5 text-3xl font-black tracking-tight text-center text-slate-700">Dashboard</div>

            <div className="grid grid-cols-2 gap-2">
                <div className="p-5 bg-white shadow rounded-xl">
                    <div className="text-sm tracking-wide text-neutral-600">Lessons Complete</div>
                    <div className="text-3xl font-black text-neutral-800">{userData?.completeLessons?.length}</div>
                </div>

                <div className="p-5 bg-white shadow rounded-xl">
                    <div className="text-sm tracking-wide text-neutral-600">Days Enrolled</div>
                    <div className="text-3xl font-black text-neutral-800">{moment().startOf('day').diff(moment(userData?.startDate).startOf('day'), 'days') + 1}</div>
                </div>
            </div>

        </div>
    )
}

