import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../logo.png';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setValues({ ...values, token: searchParams.get('token'), id: searchParams.get('id') })
  }, []);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(res => { return res.json() }).then(x => {
      if (x.status === "success") {
        window.location.replace("/login")
      }
      else {
        setError("Failed to reset password. Please try again or contact us for help.")
      }
    })
  }

  return (
    <>
      <div className="flex flex-col h-screen bg-neutral-100">
        <div className="flex flex-col justify-center flex-1 max-h-screen">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="w-64 h-auto mx-auto mb-2"
              src={logo}
            />
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="px-4 py-8 bg-white rounded shadow sm:px-10">
              <div className="text-xl font-medium text-center text-neutral-800">Reset Password</div>
              <div className="my-5 text-sm font-light text-center text-neutral-500">Enter your new password below</div>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-center text-neutral-800">
                    {error}
                  </label>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-neutral-800">
                    Password
                  </label>
                  <div className="mt-1">
                    <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-neutral-800">
                    Confirm Password
                  </label>
                  <div className="mt-1">
                    <input value={values.confirmPassword} onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                    />
                  </div>
                </div>


                <div>
                  <button
                    onClick={() => submit()}
                    type="button"
                    disabled={!values.password || values.password != values.confirmPassword}
                    className="flex justify-center w-full px-4 py-2 font-semibold text-white border border-transparent rounded shadow-sm bg-violet-500 text-md hover:bg-violet-500 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                  >
                    Reset Password
                  </button>
                  <div className="my-5 text-sm text-center text-neutral-500">
                    Go back to <a className="text-violet-400 hover:text-violet-500" href="/login">Sign In</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}