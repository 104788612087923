
import { BeakerIcon, BookOpenIcon, ChartBarSquareIcon, ClipboardDocumentCheckIcon, HomeIcon } from '@heroicons/react/24/outline';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function AppLayout() {
    const navigate = useNavigate()
    const location = useLocation();


    const getBackgroundClasses = () => {
        if (location.pathname.endsWith('dashboard')) {
            return 'from-orange-300 to-orange-600'
        }
        if (location.pathname.endsWith('programme')) {
            return 'from-yellow-300 to-yellow-600'
        }
        if (location.pathname.endsWith('health-assessment')) {
            return 'from-green-300 to-green-600'
        }
        if (location.pathname.endsWith('tracking')) {
            return 'from-blue-300 to-blue-600'
        }
        return 'from-red-300 to-red-600'

    }

    return (
        <div className={`flex flex-col min-h-screen sm:bg-gradient-to-b ${getBackgroundClasses()}`}>
            <div className="hidden from-orange-300 to-orange-600 from-yellow-300 to-yellow-600 from-green-300 to-green-600 from-red-300 to-red-600 from-blue-300 to-blue-600" ></div>
            {/* <div className='flex flex-col min-h-screen sm:bg-slate-50'> */}
            <div className='flex flex-col flex-1 w-full h-screen max-w-3xl max-h-screen mx-auto sm:max-h-full'>
                <div className='relative flex justify-around order-1 p-5 bg-white sm:shadow sm:order-0 sm:rounded-b-xl'>
                    <img src='logo.png' className="hidden h-12 sm:block"/>
                    <div className='flex flex-col items-center text-xs text-red-500 cursor-pointer' onClick={() => navigate('/')}>
                        <HomeIcon className='w-8' /> Home
                    </div>
                    <div className='flex flex-col items-center text-xs text-green-500 cursor-pointer' onClick={() => navigate('/health-assessment')}>
                        <ClipboardDocumentCheckIcon className='w-8' /> Assessments
                    </div>
                    <div className='flex flex-col items-center text-xs text-yellow-500 cursor-pointer' onClick={() => navigate('/programme')}>
                        <BookOpenIcon className='w-8' /> Programme
                    </div>
                    <div className='flex flex-col items-center text-xs text-blue-500 cursor-pointer' onClick={() => navigate('/tracking')}>
                        <BeakerIcon className='w-8' /> Track
                    </div>
                    <div className='flex flex-col items-center text-xs text-orange-500 cursor-pointer' onClick={() => navigate('/dashboard')}>
                        <ChartBarSquareIcon className='w-8' /> Dashboard
                    </div>
                </div>
                {/* <div className='relative z-50 flex-1 p-5 overflow-y-auto  sm:overflow-visible sm:order-1 order-0 sm:p-0 sm:bg-none bg-slate-50 sm:rounded-b-none rounded-b-[3rem] sm:shadow-none'> */}
                <div className={`relative z-10 flex-1 p-5 overflow-y-auto shadow sm:overflow-visible sm:order-1 order-0 sm:p-0 sm:bg-none bg-gradient-to-b ${getBackgroundClasses()} sm:rounded-b-none rounded-b-[3rem] sm:shadow-none`}>
                    {/* <div className='relative z-50 flex-1 p-5 overflow-y-auto shadow sm:overflow-visible sm:order-1 order-0 sm:p-0 sm:bg-none bg-gradient-to-b from-blue-400 to-blue-500 sm:rounded-b-none rounded-b-[3rem] sm:shadow-none'> */}
                    <Outlet></Outlet>
                </div>
            </div>
        </div>
    )
}