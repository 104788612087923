import { Popover } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

export default function HelpPopup({ text }) {
    return (
        <Popover className='absolute inline'>
            <Popover.Button>
                <div className='absolute top-0 bg-blue-500 rounded-full left-2'>
                    <QuestionMarkCircleIcon className='w-6 h-6 text-white' />
                </div>
            </Popover.Button>

            <Popover.Panel className='absolute z-10 w-64 p-3 text-sm text-center border shadow -left-20 rounded-xl bg-neutral-100 text-neutral-700'>{text}</Popover.Panel>
        </Popover>
    )
}
