import { ArrowLeftIcon, CheckIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../components/button';
import { api } from '../services/api.service';

const LessonCategoryOrder = [{ name: 'overview', order: 0, highlight: true },
{ name: 'programme preparation week', order: 1, highlight: true },
{ name: 'healthy eating', order: 2, highlight: true },
{ name: 'exercise programme', order: 3, highlight: true },
{ name: 'sleep', order: 4 },
{ name: 'mental wellbeing', order: 5 },
{ name: 'promotion of healthy relationships', order: 6 },
{ name: 'reduction of harmful substances', order: 7 },
{ name: 'making lasting changes', order: 8 },]

export default function Lessons() {
    const navigate = useNavigate();

    const [data, setData] = useState()
    const [userData, setUserData] = useState()
    const [tab, setTab] = useState(0)
    const [daysIn, setDaysIn] = useState(0)
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => { setData(x.lessons); setUserData(x.user) })
    }, [])

    useEffect(() => {
        if (userData?.startDate) {
            setDaysIn(moment().startOf('day').diff(moment(userData.startDate).startOf('day'), 'day') + 1)
        }
    }, [userData])

    if (!data) return <div></div>

    return <div className='animate-fade'>

        <div className="my-5 text-3xl font-black tracking-tight text-center text-slate-700">Personal Programme</div>
        <div className="text-lg font-medium text-center text-slate-600">New content will become available throughout your programme. Keep checking back here to see what's new.</div>

        {!userData?.healthAssessment?.age && <>
            <div className="p-5 text-center bg-white shadow rounded-xl">
                <div>Complete a health assessment to receive personalised programme content</div>
                <Button className="mt-3" text="Go To Assessment" onClick={() => navigate('/health-assessment')} />
            </div>
        </>}

        <div className='grid max-w-xl grid-cols-2 mx-auto mt-5 mb-8 overflow-hidden bg-white rounded-lg shadow-sm gap-x-4'>
            <div onClick={() => setTab(0)} className={`${tab === 0 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-2 text-center cursor-pointer`}>
                Calendar
            </div>
            <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-green-600 text-white' : 'bg-white text-neutral-800'} text-lg p-2 text-center cursor-pointer`}>
                Categories
            </div>
        </div>

        {tab == 0 && <CalendarView daysIn={daysIn} data={data} userData={userData}></CalendarView>}
        {tab == 1 && <ListView data={data.filter(x => !x.daysUntilDisplay || x.daysUntilDisplay <= daysIn)} userData={userData}></ListView>}

    </div>

}

const ListView = ({ data, userData }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const category = searchParams.get("c")

    const categories = data?.map(x => x.category).filter((x, i) => i == data.findIndex(y => y.category == x));

    const getLessonsComplete = (cat) => {
        let count = 0;
        for (let l of data.filter(x => x.category == cat)) {
            if (userData?.completeLessons?.includes(l._id)) count++;
        }
        return count;
    }

    if (category) return <div key={'sublist'} className="animate-fade">


        <div className="flex items-center justify-between">
            <div onClick={() => setSearchParams({})}><ChevronLeftIcon className="w-8 cursor-pointer text-neutral-700" /></div>
            <div className="flex-1 my-5 text-3xl font-black tracking-tight text-center text-slate-700">{category}</div>
            <div className="w-8"></div>
        </div>

        {data?.filter(x => x.category == category)?.map(x => <div onClick={() => navigate('/lesson/' + x._id)} className="flex items-center justify-between p-5 my-3 bg-white shadow cursor-pointer rounded-xl">
            <div>{x.title}</div>
            <div>
                {userData?.completeLessons?.includes(x._id) && <CheckIcon className="p-1 text-white bg-green-500 rounded-full w-7" />}
            </div>
        </div>)}

    </div>

    return (
        <div key={'list'} className="animate-fade">
            {categories?.sort((a, b) => LessonCategoryOrder.find(x => x.name == a.toLowerCase().trim())?.order - LessonCategoryOrder.find(x => x.name == b.toLowerCase().trim())?.order).map(x => <div onClick={() => setSearchParams({ c: x })}
                className={`flex items-center justify-between p-5 my-3 shadow cursor-pointer rounded-xl ${LessonCategoryOrder.find(y => y.name == x.toLowerCase().trim())?.highlight ? 'bg-blue-500 text-white' : 'bg-white '}`}>
                <div>{x}</div>
                <div>{getLessonsComplete(x)}/{data?.filter(y => y.category == x)?.length}</div>
            </div>)}

        </div>
    )
}

const CalendarView = ({ data, userData, daysIn }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [day, setDay] = useState(null)

    const CalendarContentDisplay = ({ day }) => {
        if (data.filter(x => x.daysUntilDisplay == day)?.length == 0) return <div>Nothing for this day</div>

        return data.filter(x => x.daysUntilDisplay == day).map(x => <div onClick={() => navigate('/lesson/' + x._id)} className="p-1 my-1 text-center border rounded cursor-pointer select-none">
            {x.category} {x.title}
        </div>)
    }

    if (day) {
        return <div>
            <div className="cursor-pointer text-neutral-800" onClick={() => setDay(null)}><ArrowLeftIcon className="inline w-6 mr-2"/> Back</div>
            <div className="p-3 my-5 bg-white rounded-lg shadow">
                <div className="text-lg font-medium text-neutral-700">Day {day}</div>
                <CalendarContentDisplay day={day}></CalendarContentDisplay>
            </div>
        </div>
    }

    return <div className="grid grid-cols-6 gap-2">
        {Array.from(Array(28).keys()).map((x, i) => <div onClick={() => setDay(i + 1)} className={`${(daysIn >= (i + 1)) ? 'bg-blue-500 text-white' : 'bg-white'} p-2 text-lg text-center  rounded-md shadow cursor-pointer text-bold`}>Day {i + 1}</div>)}
    </div>
}