import logo from '../logo.png'

export default function AuthLayout({ children }) {
    return (
        <div className='flex flex-col h-screen bg-gradient-to-b from-green-400 to-green-500'>
            <div className='flex flex-col justify-center flex-1 max-h-screen'>

                <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-lg'>
                    <div className='px-4 py-8 bg-white rounded shadow sm:px-10'>
                        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                            <img className='w-64 h-auto p-5 mx-auto mb-2 ' src={logo} alt='' />
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
