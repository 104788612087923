import draftToHtml from 'draftjs-to-html'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate, useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Button from '../components/button'

export default function Lesson() {
    const [data, setData] = useState()
    const [completed, setCompleted] = useState(false)
    const [currentTab, setCurrentTab] = useState(null)
    const navigate = useNavigate()
    const params = useParams()
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/lesson/${params.id}`).then((x) => setData(x))
        api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
            if (x.user?.completeLessons?.includes(params.id)) {
                setCompleted(true)
            }
        })
    }, [])

    useEffect(() => {
        if (data?.tabContent && data?.tabContent.length > 0) {
            setCurrentTab(0)
        }
    }, [data])

    const markAsComplete = () => {
        if (!completed) api(`${process.env.REACT_APP_API_URL}/app/mark-complete/${params.id}`).then((x) => setCompleted(true))
    }

    if (!data) return null
    const markup = draftToHtml(data.content)

    return (
        <div className='animate-fade'>
            <div className='flex items-center justify-between'>
                <div onClick={() => navigate(-1)}>
                    <ChevronLeftIcon className='w-8 cursor-pointer text-neutral-700' />
                </div>
                <div className='flex-1 my-5 text-3xl font-black tracking-tight text-center text-slate-700'>{data.title}</div>
                <div className='w-8'>{completed && <CheckIcon className='p-1 text-white bg-green-500 rounded-full' />}</div>
            </div>

            <div className='p-5 bg-white shadow rounded-xl'>
                <div className='html-display' dangerouslySetInnerHTML={{ __html: markup }}></div>
                {data?.tabContent && data.tabContent.length > 0 && (
                    <div>
                        <div className='flex flex-row items-center mt-6 mb-4 -mx-5 border-b gap-x-6 border-b-slate-400 px-9'>
                            {data.tabContent?.map(({ title, content }, idx) => (
                                <div className={`text-slate-700 text-lg rounded-t-md hover:bg-slate-200 px-3 py-2 cursor-pointer select-none font-bold ${idx === currentTab ? 'bg-slate-300' : ''}`} onClick={() => setCurrentTab(idx)}>
                                    {title}
                                </div>
                            ))}
                        </div>
                        {currentTab !== null && <div className='html-display' dangerouslySetInnerHTML={{ __html: draftToHtml(data.tabContent[currentTab].content) }}></div>}
                    </div>
                )}
            </div>

            {data.videoUrls && data.videoUrls.length > 0 && (
                <div className='flex flex-col p-5 mt-10 bg-white shadow rounded-xl'>
                    {data.videoUrls.map((url) => (
                        <div className='mx-auto my-5'>
                            <ReactPlayer className='' url={url} />
                        </div>
                    ))}
                </div>
            )}

            {!completed && <Button className='mt-5' text='Mark as Complete' onClick={() => markAsComplete()} />}
        </div>
    )
}
