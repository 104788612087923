import moment from 'moment'
import { Line as RCLine, LineChart as RCLineChart, XAxis, YAxis } from 'recharts'
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import HelpPopup from '../components/help-popup'

export function Sparkline({ data }) {
    const [expanded, setExpanded] = useState(false)
    const _data = data.map((x) => {
        return { count: x }
    })
    if (data?.length > 1)
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    setExpanded(!expanded)
                }}
                className={expanded ? 'relative bg-white shadow-lg rounded-lg z-10 pr-4 pt-4' : 'cursor-pointer'}
            >
                <RCLineChart width={expanded ? 380 : 180} height={expanded ? 280 : 80} data={_data}>
                    <XAxis tick={false} />
                    <YAxis tick={false} domain={[0, Math.max(..._data.map((d) => d.count)) * 1.1]} />
                    <RCLine dot={false} dataKey='count' stroke='#5DC79B' strokeWidth={2} isAnimationActive={false} />
                </RCLineChart>
            </div>
        )
    return null
}

export const MetricCard = ({ metric, data, getValue, setModalOpen, usingImperial }) => {
    const [open, setOpen] = useState(false)

    return (
        <div onClick={() => setOpen(!open)} className='p-3 my-3 bg-white rounded-lg shadow'>
            <div className='flex items-center justify-between space-x-4 '>
                <div>
                    <div className='flex'>
                        <div className='text-sm font-medium text-neutral-600'>{metric.label}</div>
                        <div className='relative'>
                            <HowToPopup type={metric.type} />
                        </div>
                    </div>

                    <div className='text-xl tracking-wide text-neutral-800'>{displayValue(getValue(metric.type), metric, usingImperial)}</div>
                </div>
                <div className='relative flex-1'>
                    <div className='absolute -top-5 right-5'>
                        <Sparkline
                            data={data
                                ?.sort((a, b) => (new Date(a) > new Date(b) ? -1 : 1))
                                ?.filter((x) => x.type == metric.type)
                                ?.map((x) => +x?.data)}
                        />
                    </div>
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        setModalOpen({ label: metric.label, unit: metric.unit, type: metric.type })
                    }}
                >
                    <XMarkIcon className='w-6 rotate-45 cursor-pointer' />
                </div>
            </div>
            {open && (
                <div>
                    {data
                        ?.sort((a, b) => (new Date(a) > new Date(b) ? -1 : 1))
                        ?.filter((x) => x.type == metric.type)
                        ?.map((x) => (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setModalOpen({ _id: x._id, label: metric.label, unit: metric.unit, type: metric.type })
                                }}
                                className='flex items-center justify-between p-1 border-b border-slate-100'
                            >
                                <div>
                                    <div className='text-xs text-neutral-600'>{moment(x.date).format('Do MMM')}</div>
                                    <div className='text-lg font-medium text-neutral-800'>{displayValue(x.data, metric, usingImperial)}</div>
                                </div>

                                <div>
                                    <PencilIcon className='w-6 text-neutral-600' />
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}

function displayValue(value, metric, usingImperial) {
    if (usingImperial) {
        if (metric.type === 'weight') {
            value = value / 6.35029318 // stones
            return `${isNaN(value) ? '- ' : Math.floor(value)}st ${isNaN(value) ? '- ' : Math.round(140 * (value % 1)) / 10}lbs`
        }
        if (metric.type === 'waist_circumference') {
            value = value * 0.3937008 // cm to inches
            return `${isNaN(value) ? '-' : Math.round(value * 10) / 10} in`
        }
    }

    return `${value} ${metric.unit}`
}

const HowToPopup = ({ type }) => {
    if (type == 'resting_heart_rate') {
        return <HelpPopup text="At the wrist, lightly press the index and middle fingers of one hand on the opposite wrist, just below the base of the thumb. At the neck, lightly press the side of the neck, just below your jawbone. Count the number of beats in 15 seconds, and multiply by four. That's your heart rate." />
    }
    if (type == 'waist_circumference') {
        return <HelpPopup text="Find the bottom of your ribs and the top of your hips. Place a tape measure around your middle at a point halfway between them (just above the belly button). Make sure it's pulled tight, but isn't digging into your skin. Breathe out naturally and take your measurement." />
    }
}
