import { Switch } from '@headlessui/react'
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Line as RCLine, LineChart as RCLineChart, XAxis, YAxis } from 'recharts'

import { api } from '../services/api.service'

import Button from '../components/button'

import DateField from '../forms/fields/date-field'
import NumberField from '../forms/fields/number-field'
import FormModal from '../forms/form-modal'

import { MetricCard, Sparkline } from './tracking-common'

const PHYSICAL_METRICS = [
    // { label: 'Blood Pressure', unit: 'mg/Hg', type: 'blood_pressure' },
    { label: 'Resting Heart Rate', unit: 'bpm', type: 'resting_heart_rate' },
    { label: 'Weight', unit: 'kg', type: 'weight' },
    { label: 'Waist Circumference', unit: 'cm', type: 'waist_circumference' },
]

export default function TrackingPhysical({ data, setModalOpen, onGetRefresh, usingImperial, setUsingImperial }) {
    function getValue(type) {
        let d = data.sort((a, b) => (new Date(a) > new Date(b) ? -1 : 1)).filter((x) => x.type == type)[data.filter((x) => x.type == type)?.length - 1]
        if (d?.data != 0 && !d?.data) return '-'
        return +d?.data
    }

    return (
        <div className='animate-fade'>
            <div className='flex items-center justify-between my-3 text-xl font-semibold tracking-tight text-slate-700'>
                Physical Measurements
                <div className='flex items-center gap-4'>
                    {usingImperial ? 'Imperial' : 'Metric'}
                    <Switch
                        checked={usingImperial}
                        onChange={setUsingImperial}
                        className={`${usingImperial ? 'bg-teal-900' : 'bg-teal-700'}
  relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <span className='sr-only'>Use Imperial units</span>
                        <span
                            aria-hidden='true'
                            className={`${usingImperial ? 'translate-x-7' : 'translate-x-0'}
    pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                    </Switch>
                </div>
            </div>

            <BloodPressureCard metric={{ label: 'Blood Pressure', unit: 'mmHg', type: 'blood_pressure' }} data={data} triggerRefresh={onGetRefresh} />

            {PHYSICAL_METRICS.map((x) => (
                <MetricCard metric={x} data={data} getValue={getValue} setModalOpen={setModalOpen} usingImperial={usingImperial} />
            ))}

            <HeightCard metric={{ label: 'Height', unit: 'm', type: 'height' }} data={data} getValue={getValue} setModalOpen={setModalOpen} usingImperial={usingImperial} />

            <BmiCard height={getValue('height')} metric={{ label: 'BMI', type: 'weight' }} data={data} getValue={getValue} setModalOpen={setModalOpen}></BmiCard>
        </div>
    )
}

const HeightCard = ({ metric, data, getValue, setModalOpen, usingImperial }) => {
    const [open, setOpen] = useState(false)
    const id = data?.find((x) => x.type == 'height')?._id

    function getMetricText() {
        let value = getValue(metric.type)

        if (usingImperial) {
            value = value * 3.28084 // feet
            return `${Math.floor(value)}ft ${Math.round(120 * (value % 1)) / 10}in`
        }

        return `${value} ${metric.unit}`
    }

    return (
        <div onClick={() => setOpen(!open)} className='p-3 my-3 bg-white rounded-lg shadow'>
            <div className='flex items-center justify-between space-x-4 '>
                <div>
                    <div className='text-sm font-medium text-neutral-600'>{metric.label}</div>
                    <div className='text-xl tracking-wide text-neutral-800'>{getMetricText()}</div>
                </div>
                <div className='relative flex-1'></div>
                {id && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            setModalOpen({ _id: id, label: metric.label, unit: metric.unit, type: metric.type })
                        }}
                    >
                        <PencilIcon className='w-6 text-neutral-600' />
                    </div>
                )}
                {!id && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            setModalOpen({ label: metric.label, unit: metric.unit, type: metric.type })
                        }}
                    >
                        <PencilIcon className='w-6 text-neutral-600' />
                    </div>
                )}
            </div>
        </div>
    )
}

const BmiCard = ({ metric, data, getValue, setModalOpen, height }) => {
    const [open, setOpen] = useState(false)
    const getBMI = (val) => {
        let out = Math.round((val / (height * height)) * 10) / 10
        if (isNaN(out)) {
            return '-'
        }
        return out
    }
    return (
        <div onClick={() => setOpen(!open)} className='p-3 my-3 bg-white rounded-lg shadow'>
            <div className='flex items-center justify-between space-x-4 '>
                <div>
                    <div className='text-sm font-medium text-neutral-600'>{metric.label}</div>
                    <div className='text-xl tracking-wide text-neutral-800'>{getBMI(getValue(metric.type))}</div>
                </div>
                <div className='relative flex-1'>
                    <div className='absolute -top-5 right-5'>
                        <Sparkline
                            data={data
                                ?.sort((a, b) => (new Date(a) > new Date(b) ? -1 : 1))
                                ?.filter((x) => x.type == metric.type)
                                ?.map((x) => getBMI(+x?.data))}
                        />
                    </div>
                </div>
                <div>
                    <div className='w-6' />
                </div>
            </div>
            {open && (
                <div>
                    {data
                        ?.sort((a, b) => (new Date(a) > new Date(b) ? -1 : 1))
                        ?.filter((x) => x.type == metric.type)
                        ?.map((x) => (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setModalOpen({ _id: x._id, label: metric.label, unit: metric.unit, type: metric.type })
                                }}
                                className='flex items-center justify-between p-1 border-b border-slate-100'
                            >
                                <div>
                                    <div className='text-xs text-neutral-600'>{moment(x.date).format('Do MMM')}</div>
                                    <div className='text-lg font-medium text-neutral-800'> {x.data}</div>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}

const BloodPressureCard = ({ metric, data, triggerRefresh }) => {
    const [open, setOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const [recentOfType, setRecent] = useState(getRecent(data))

    const [value, setValue] = useState([null, null])
    const [date, setDate] = useState(new Date())

    function getRecent(data) {
        return data?.filter(({ type }) => type === 'blood_pressure')?.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1)) ?? []
    }

    useEffect(() => {
        setRecent(getRecent(data))
    }, [data])

    function postNewValue() {
        if (modalOpen._id) {
            api(`${process.env.REACT_APP_API_URL}/app/tracking`, { data: { _id: modalOpen._id, type: modalOpen.type, data: value, date: date } }).then((x) => {
                triggerRefresh()
                setModalOpen(false)
                setValue([null, null])
                setDate(new Date())
            })
        } else {
            api(`${process.env.REACT_APP_API_URL}/app/tracking`, { data: { type: modalOpen.type, data: value, date: date } }).then((x) => {
                triggerRefresh()
                setModalOpen(false)
                setValue([null, null])
                setDate(new Date())
            })
        }
    }

    return (
        <>
            <FormModal open={!!modalOpen} setOpen={setModalOpen}>
                <div className='p-5 text-left bg-white rounded-lg'>
                    <NumberField label='Systolic Pressure (mmHg)' value={value[0]} onChange={(val) => setValue((prev) => [val, prev[1]])} />
                    <NumberField label='Diastolic Pressure (mmHg)' value={value[1]} onChange={(val) => setValue((prev) => [prev[0], val])} />
                    <DateField label='Date' value={date} onChange={setDate} />
                    <div className='flex mt-4 space-x-4'>
                        <Button
                            className='bg-gray-500 hover:bg-gray-600'
                            text='Cancel'
                            onClick={() => {
                                setModalOpen(false)
                                setValue([null, null])
                                setDate(new Date())
                            }}
                        />
                        <Button className='flex-1 w-full' text='Save' onClick={() => postNewValue()} />
                    </div>
                </div>
            </FormModal>
            <div onClick={() => setOpen(!open)} className='p-3 my-3 bg-white rounded-lg shadow'>
                <div className='flex items-center justify-between space-x-4 '>
                    <div>
                        <div className='flex'>
                            <div className='text-sm font-medium text-neutral-600'>{metric.label}</div>
                        </div>

                        <div className='text-xl tracking-wide text-neutral-800'>
                            {recentOfType?.slice(-1)[0]?.data.join('/')} {metric.unit}
                        </div>
                    </div>
                    <div className='relative flex-1'>
                        <div className='absolute -top-5 right-5'>
                            <BloodPressureSparkline data={recentOfType} />
                        </div>
                    </div>
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            setModalOpen({ label: metric.label, unit: metric.unit, type: metric.type })
                        }}
                    >
                        <XMarkIcon className='w-6 rotate-45 cursor-pointer' />
                    </div>
                </div>
                {open && (
                    <div>
                        {recentOfType?.map((x) => (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setModalOpen({ _id: x._id, label: metric.label, unit: metric.unit, type: metric.type })
                                }}
                                className='flex items-center justify-between p-1 border-b border-slate-100'
                            >
                                <div>
                                    <div className='text-xs text-neutral-600'>{moment(x.date).format('Do MMM')}</div>
                                    <div className='text-lg font-medium text-neutral-800'> {x.data.join('/')}</div>
                                </div>

                                <div>
                                    <PencilIcon className='w-6 text-neutral-600' />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

const BloodPressureSparkline = ({ data }) => {
    const [expanded, setExpanded] = useState(false)
    const _data = data.map((x) => ({ sys: x.data[0], dia: x.data[1] }))

    if (data?.length > 1)
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    setExpanded(!expanded)
                }}
                className={expanded ? 'relative bg-white shadow-lg rounded-lg z-10 pr-4 pt-4' : ''}
            >
                <RCLineChart width={expanded ? 380 : 180} height={expanded ? 280 : 80} data={_data}>
                    <XAxis tick={false} />
                    <YAxis tick={false} domain={[0, 180]} />
                    <RCLine dot={false} dataKey='dia' stroke='#9218d1' strokeWidth={2} />
                    <RCLine dot={false} dataKey='sys' stroke='#5DC79B' strokeWidth={2} />
                </RCLineChart>
            </div>
        )
    return null
}
