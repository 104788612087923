import { XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '../components/button'
import PageHeader from '../components/page-header'

import { MultiSelectField, NumberField, SelectField, TextField, WysiwygField } from './fields'
import FormWrapper from './form-wrapper'

export default function LessonForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    const [newUrl, setNewUrl] = useState('')

    return (
        <div>
            <PageHeader headline='Manage Lesson'></PageHeader>
            <FormWrapper url='admin/lesson' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Category' value={values.category} onChange={(val) => setValues({ ...values, category: val })} />
                        <TextField label='Title' value={values?.title ?? ''} onChange={(val) => setValues({ ...values, title: val })} />
                        <NumberField label='Days Until Displayed' value={values.daysUntilDisplay} onChange={(val) => setValues({ ...values, daysUntilDisplay: val })} />

                        <div className='p-3 my-5 border rounded-lg'>
                            <div className='text-neutral-800'>Rule to display</div>
                            <div className='text-sm text-neutral-700'>Leave this section blank if this content should be universally applicable. If you want to make it only available based on the health assessment, select a question and weather the user should see this content if they select yes/no</div>
                            <SelectField
                                label='Question'
                                options={[
                                    { text: 'Sex', value: 'sex' },
                                    { text: 'Angina or heat attack in a 1st degree relative < 60?', value: 'anginaInRelative' },
                                    { text: 'Chronic kidney disease (stage 3,4 or 5)?', value: 'kidneyDisease' },
                                    { text: 'Atrial fibrillation?', value: 'atrialFibrillation' },
                                    { text: 'On blood pressure treatment?', value: 'bloodPressureTreatment' },
                                    { text: 'Do you have migraines?', value: 'migraines' },
                                    { text: 'Rheumatoid arthritis?', value: 'rheumatoidArthritis' },
                                    { text: 'Systemic lupus erythematosus (SLE)?', value: 'sle' },
                                    { text: 'Severe mental illness?', value: 'mentalIllness' },
                                    { text: 'On atypical antipsychotic medication?', value: 'antipsychoticMedication' },
                                    { text: 'Are you on regular steroid tablets?', value: 'steroidTablets' },
                                    { text: 'A diagnosis of or treatment for erectile disfunction?', value: 'ed' },
                                ]}
                                value={values.rule?.source}
                                onChange={(val) => setValues({ ...values, rule: { ...(values.rule ?? {}), source: val } })}
                            />

                            {values?.rule?.source == 'Sex' && (
                                <>
                                    <MultiSelectField
                                        options={[
                                            { value: 'Female', text: 'Female' },
                                            { value: 'Male', text: 'Male' },
                                            { value: 'Other', text: 'Other' },
                                        ]}
                                        label='Sex'
                                        value={values.rule?.sex}
                                        onChange={(val) => setValues({ ...values, rule: { ...(values.rule ?? {}), sex: val } })}
                                    />
                                </>
                            )}
                            {values?.rule?.source && values?.rule?.source != 'Sex' && (
                                <>
                                    <SelectField
                                        options={[
                                            { value: 'Yes', text: 'Yes' },
                                            { value: 'No', text: 'No' },
                                        ]}
                                        label='Yes/No'
                                        value={values.rule?.checked}
                                        onChange={(val) => setValues({ ...values, rule: { ...(values.rule ?? {}), checked: val } })}
                                    />
                                </>
                            )}
                        </div>

                        <div className='p-3 my-5 border rounded-lg'>
                            <div className='text-neutral-800'>Video Content Urls</div>
                            <ul className='flex flex-col my-2 gap-y-1'>
                                {values?.videoUrls?.map((url) => (
                                    <li className='flex flex-row items-center gap-2 px-2 py-1 border rounded w-fit'>
                                        <span>{url}</span>
                                        <XMarkIcon className='w-6 h-6' onClick={() => setValues({ ...values, videoUrls: values?.videoUrls?.filter((d) => d !== url) ?? [] })} />
                                    </li>
                                ))}
                            </ul>
                            <TextField label='Add Video URL' value={newUrl} onChange={(val) => setNewUrl(val)} />
                            <Button
                                text='Add Url'
                                onClick={() => {
                                    setValues({ ...values, videoUrls: [...(values?.videoUrls ?? []), newUrl] })
                                    setNewUrl('')
                                }}
                                className='mt-3'
                            />
                        </div>

                        <WysiwygField label='Main Content' value={values.content} onChange={(val) => setValues({ ...values, content: val })} />

                        <div className='p-3 my-5 border rounded-lg'>
                            <div className='text-neutral-800'>Tabular Content</div>
                            {values?.tabContent && values.tabContent?.length > 0 ? (
                                <div className='flex flex-col gap-y-4'>
                                    {values.tabContent?.map(({ title, content }, idx) => (
                                        <div key={idx} className='p-4 border rounded border-slate gap-y-2'>
                                            <TextField value={title} onChange={(v) => setValues((p) => ({ ...p, tabContent: p.tabContent.map((d, i) => (i === idx ? { ...d, title: v } : d)) }))} />
                                            <WysiwygField value={content} onChange={(val) => setValues({ ...values, tabContent: values.tabContent.map((d, i) => (i === idx ? { ...d, content: val } : d)) })} />
                                            <div className='flex flex-row justify-end'>
                                                <Button
                                                    text={`Delete ${title}`}
                                                    onClick={() => {
                                                        setValues((p) => ({ ...p, tabContent: p.tabContent.filter((_, i) => i !== idx) }))
                                                    }}
                                                    className='mt-3 bg-amber-600 hover:bg-amber-700'
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <h1 className='mt-3 text-slate-600'>No tab content</h1>
                            )}

                            <Button
                                text='Add Tab'
                                onClick={() => {
                                    setValues({ ...values, tabContent: [...(values?.tabContent ?? []), { title: '', content: '' }] })
                                }}
                                className='mt-3 '
                            />
                        </div>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
